* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: black;
  color: white;
}

@import 'react-toastify/dist/ReactToastify.css';