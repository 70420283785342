nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo {
    width: 140px;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 17px;
    position: relative;
}

nav ul li a::after {
    content: '';
    width: 0;
    height: 3px;
    background: #ef5327;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: 0.5s;
}

nav ul li a:hover::after {
    width: 100%;
}

nav .fa-circle-xmark, .fa-bars {
    display: none;
}


/**********Responsive section**********/

@media screen and (max-width: 800px) {
    nav .fa-circle-xmark, .fa-bars {
        display: block;
        font-size: 25px;
        cursor: pointer;
    }

    nav ul {
        background: #ef5327;
        position: fixed;
        top: 0;
        right: -200px;
        width: 200px;
        height: 100vh;
        padding-top: 50px;
        z-index: 2;
        transition: right 0.5s;
    }

    nav ul li {
        display: block;
        margin: 25px;
    }

    nav ul .fa-bars, .fa-circle-xmark {
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
    }

    nav ul.active {
        right: 0;
    }

}