.container-blog {
    padding: 10px 20%;
}

.blog-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.blog {
    width: 355px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 20px;
    cursor: pointer;
    transition: 0.5s ease;
}

.blog:hover {
    transform: scale(1.1);
    
}

.blog img {
    width: 100%;
}

.blog h3 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 10px;
    padding: 15px 5%
}

.blog-link {
    text-align: center;
    margin-bottom: 20px;
}

.blog-link a {
    text-decoration: none;
    color: white;
    transition: 0.5s;
}

.blog-link a:hover {
    color: #ef5327;
}

.blog hr {
    margin: auto;
    width: 75%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.author {
    text-align: center;
    margin-bottom: 10px;
}


.blog-content-container h1 {
    margin-top: 40px;
    text-align: center;
    font-size: 35px;
}

.blog-content-container h2 {
    text-align: center;
    margin-top: 20px;
}

.blog-content-container hr {
    margin: auto;
    width: 30%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.blog-body p {
    text-align: left;
    line-height: 40px;
    font-size: 20px;
}

.emphasis strong {
    font-size: 25px;
}

.newton {
    font-size: 25px;
    color: #ef5327;
}

.emphasis p {
    padding-left: 65px;
}

.sub-title {
    font-size: 35px;
}

.blog-content-container h3 {
    font-size: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.link {
    color: #ef5327;
}

.subscriber-container h1 {
    text-align: center;
    margin-top: 50px;

}

.subscriber-container p {
    text-align: center;
    margin-top: 20px;
}

.sub-container h1 {
    text-align: center;
    font-size: 55px;
    margin-top: 50px;
}

.sub-container p {
    text-align: center;
    margin: 20px 20%;
}

/**********Responsive section**********/

@media screen and (max-width: 800px) {
    .blog-header {
        height: 10vh;
    }


    .blog-title h1 {
        font-size: 35px;
    }

    .blog-title p {
        font-size: 15px;
        padding: 10px 10%;
        line-height: 30px;
    }

    

    .subscriber-container form input {
        width: 50%;
    }

    .blog-content-container h1 {
        font-size: 24px;
    }

    .blog-content-container h2 {
        font-size: 20px;
    }

    .blog-body p {
        font-size: 15px;
    }

    .container-blog {
        padding: 10px 10%;
    }

    .subscriber-container h1 {
        font-size: 24px;
    }

    .subscriber-container p {
        font-size: 15px;
    }

    .sub-container h1 {
        font-size: 40px;
    }

    .sub-container p {
        margin: 20px 0;
    }


}